<template>
  <div v-if="open" class="c-app-banner js-banner-app">
    <button class="c-app-banner__close" @click="close">
      <CloseIcon :title="$t('components.molecules.header.appBanner.close')" />
    </button>
    <button class="c-app-banner__download" @click="download">
      <div>
        <ClientOnly>
          <img
            class="c-app-banner__logo"
            width="49"
            height="49"
            :src="!isInternationalDisplay ? 'https://assets.laboutiqueofficielle.com/assets/general/laboutiqueofficielle_square_logo.svg' : 'https://assets.laboutiqueofficielle.com/assets/general/ryses_square_logo.svg'"
            :alt="currentInterStore && isInternationalDisplay ? currentInterStore.displayName : currentInterStore.id"
          >
        </ClientOnly>
        <p class="c-app-banner__info">
          <strong>{{ $t('components.molecules.header.appBanner.title') }}</strong><br>
          {{ $t('components.molecules.header.appBanner.subtitle') }}
        </p>
      </div>
      <span class="c-app-banner__text">{{ $t('components.molecules.header.appBanner.openApp') }}</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CloseIcon from '@/components/atoms/icons/Close'
import { APP_BANNER_NB_OF_HIDING_DAYS } from '@/config'
import { APP_LINKS } from '@/config/global'

export default {
  components: { CloseIcon },
  props: {
    currentInterStore: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      open: true,
      hasClosedAppBanner: false
    }
  },
  computed: {
    ...mapGetters({ isInternationalDisplay: 'international/isInternationalDisplay' })
  },
  mounted () {
    this.hasClosedAppBanner = this.$cookies.get('hasClosedAppBanner') || false
    this.open = (this.$ua.isFromAndroidOs() || this.$ua.isFromIphone()) && !this.hasClosedAppBanner
  },
  methods: {
    close () {
      this.open = false
      this.$cookies.set('hasClosedAppBanner', 'true', { path: '/', maxAge: 60 * 60 * 24 * APP_BANNER_NB_OF_HIDING_DAYS })
    },
    download () {
      if (this.$ua.isFromAndroidOs()) {
        window.location.href = APP_LINKS.googlePlay[this.currentInterStore.id]
      } else if (this.$ua.isFromIphone()) {
        window.location.href = APP_LINKS.appStore[this.currentInterStore.id]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-app-banner {
  display: none;
  min-height: 85px;
  align-items: center;
  justify-content: space-between;
  padding: 1.8rem 0;
  background: $grey-100;
  color: $grey-600;
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;

  &__download {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    padding-right: 2rem;
    text-decoration: none;

    >div {
      display: flex;
      align-items: center;
    }

    @include mq($until: mobile) {
      padding-right: 1rem;
    }
  }

  @include mq($from: tablet) {
    display: none;
  }

  &__logo {
    width: 100%;
    max-width: 4.9rem;
  }

  &__text {
    display: block;
    padding: 1rem;
    border: none;
    margin: 0;
    background: none;
    background-color: $primary;
    color: $white;
    font-size: 1.2rem;
    font-weight: 900;
    letter-spacing: 0.16rem;
    text-decoration: none;
    text-transform: uppercase;

    @include mq($until: mobile) {
      font-size: 1.1rem;
    }
  }

  &__info {
    padding-left: 1.4rem;
    margin: 0;
    color: $white;
    font-size: 1.1rem;
    text-align: left;

    strong {
      font-weight: 900;
    }
  }

  &__close {
    display: flex;
    flex: 0 0 4.1rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    background: none;

    svg {
      display: block;
      width: 1rem;
      height: auto;
      fill: $grey-600;
    }

    @include mq($until: mobile) {
      flex: 0 0 3rem;
    }
  }
}

// On affiche uniquement la bannière si on est sur un device tactile
@media only screen and (hover: none) and (pointer: coarse) {
  .c-app-banner {
    display: flex;
  }
}
</style>
